@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700');

:root {
  --dark: #1A1A1A;
  --light-grey: #D9D9D9;
  --mid-grey: #7D7D7D;

  --base-font-size: 1rem;
  --base-line-hight: 1.5rem;
  --img-size: 48px;

  /* --min-width: 1248px; */
}

@media (min-width: 2000px) {
  :root {
    font-size: 20px;
    --img-size: 60px;
  }
}


* {
  font-family: 'Inter', sans-serif;
  font-size: 1rem;
  /* line-height: var(--base-line-hight); */
  font-weight: 400;
  color: White;
  text-decoration: none;

  background-color: var(--dark);

  box-sizing: border-box;

  /* max-width: 99vw; */
  max-width: 1900px;
  /* margin: auto; */
}

ol {
  list-style: decimal;
}

button {
  border: none;
}

/* --- CSS Section For Header --- */

header {
  margin-top: 0;
  top: 0;
  width: 99vw;
  /* min-width: var(--min-width); */
  /* height: 90px; */
  /* min-height: 90px; */

  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2%;

  position: fixed;
  z-index: 2;
}

header span {
  padding: 0;
  width: 33%;
  align-items: left;
}

header .mobile-header {
  text-align: center;
  width: 100%;
}

header button {
  padding: 0;
  width: 33%;
  margin-left: auto;
  margin-right: auto;
}

header .header-icon {
  width: var(--img-size);
  height: var(--img-size);
  display: inline;
  /* filter: invert(99%) sepia(32%) saturate(42%) hue-rotate(131deg) brightness(117%) contrast(100%); */
}

header nav {
  padding: 0;
  width: 33%;
}

header nav ul {
  padding: 0;
  align-items: right;
  float: right;
  list-style: none;
}

header nav ul li {
  display: inline-block;
  padding: 0% 13px;
}

header nav ul li a {
  transition: all 0.3s ease 0s;
}

header nav ul li a:hover {
  color: rgb(129, 126, 126);
}

span a {
  transition: all 0.3s ease 0s;
}

span a:hover {
  color: rgb(129, 126, 126);
}

/* button a img {
  transition: all 0.3s ease 0s;
} */

/* button a img:hover {
  filter: invert(46%) sepia(0%) saturate(25%) hue-rotate(137deg) brightness(106%) contrast(86%);
} */

/* a:hover{
  color: rgb(129, 126, 126);
} */

/* --- CSS Section For Landing --- */

.landing-grid {
  display: grid;
  grid-template-rows: 1fr 2fr 2fr 2fr 1fr;

  margin-top: 100px;

  width: 99vw;
  /* min-width: var(--min-width); */
  /* height: 88vh; */

  text-align: right;

  padding-right: 2%;
}

.landing-grid-item {
  /* border: 1px solid white; */
  margin-bottom: 1.5%;
}

.landing-text {
  /* font-size: 130px; */
  text-align: right;
  /* margin-right: 5%; */
  margin-top: 0;
  margin-bottom: 0;
}

.landing-text-inverted {
  /* font-size: 50px; */
  color: #1A1A1A;

  text-align: right;
  text-align: bottom;
  margin-right: 2%;
  margin-left: 0;
  margin-top: 0;
  margin-bottom: 0;

  padding-left: 8px;
  padding-right: 8px;

  background-color: #D9D9D9;
}

.font-size-normal-resp {
  /* font-size: 50px; */
  font-size: 3rem;
}

.font-size-inverted-resp {
  font-size: 1.875rem;
  /* font-size: 30px; */
}

/* --- CSS Section For Divider --- */
.divider-grid {
  display: grid;
  grid-template-columns: 4fr 1fr;

  margin: auto;

  width: 95vw;
  min-width: var(--min-width);
  /* height: 5vw; */
  min-height: 70px;
}

.divider-grid-right {
  display: grid;
  grid-template-columns: 4fr 3fr;

  margin: auto;

  width: 95vw;
  /* height: 5vw; */
}

.divider-grid-left {
  display: grid;
  grid-template-columns: 2.1fr 4fr;

  margin: auto;

  width: 95vw;
  /* height: 5vw; */
}

.divider-grid-item {
  display: flex;
  align-items: center;

}

.divider-line {
  width: 99%;
  z-index: 1;
}

.divider-text-inverted {
  /* font-size: 50px; */
  font-size: 3rem;
  color: #1A1A1A;

  text-align: center;
  text-align: bottom;
  /* margin-right: 2%; */
  margin-left: 0;
  margin-top: 0;
  margin-bottom: 0;

  padding-left: 8px;
  /* padding-right: 8px; */
  min-width: 490px;
  background-color: #D9D9D9;

  z-index: 1;
}

/* --- CSS Section For Divider --- */
.experience-grid {
  /* border: 1px solid white; */
  display: grid;
  grid-template-columns: 1.6fr 0.4fr 3fr;

  margin: auto;
  padding-left: 2%;

  width: auto;
  min-width: var(--min-width);
  height: 700px;
}

.experience-grid-item {
  position: relative;
  margin-right: 2%;
}

.mindWidth1 {
  min-width: 800px;
}

/* .border {
  border: 1px solid white;
} */

.experience-grid-center {
  /* border: 1px solid red; */

  /* height: 80%;
  width: 50%; */
  /* min-width: 760px; */

  margin-top: auto;
  margin-bottom: auto;
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  /* margin-left: 25%; */
  margin-right: 2%;
  width: 100%;

  display: flex;
  flex-direction: column;

}

.experience-grid-center-projects {
  /* border: 1px solid red; */

  /* height: 80%;
  width: 20%; */
  /* min-width: 200px; */
  margin-top: auto;
  margin-bottom: auto;
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  margin-bottom: auto;
  /* margin-left: 15%; */

  align-items: center;

  grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr;
}

.experience-grid-center-HIW {
  /* border: 1px solid red; */

  /* height: 80%; */
  width: 50%;
  min-width: 760px;
  margin-top: 2%;
  margin-bottom: auto;
  margin-left: 15%;

  grid-template-rows: 1fr 1fr 1fr;
}

.experience-grid-center-projects-item {
  border-top: 1px solid rgb(188, 186, 186);
  border-bottom: 1px solid rgb(188, 186, 186);
  padding-bottom: 10px;
  /* padding-top: 10px; */

  /* min-height: 80px; */
  width: 100%;
  /* min-width: 800px; */
  margin-top: 1%;

  padding-top: 15px;

}

.experience-grid-center-projects-item2 {
  /* border-top: 1px solid white;
  border-bottom: 1px solid white; */

  /* min-height: 80px; */
  min-width: 800px;
  margin-top: 3%;

}

.project-item-header {
  font-weight: 700;
  text-align: left;
}

.project-item-header2 {
  font-weight: 700;
  text-align: left;
  color: rgb(188, 186, 186);
}

.project-item-tec {
  /* font-style: italic; */
  text-align: left;
  margin-top: 2px;
  margin-bottom: 0px;
  color: rgb(188, 186, 186);
  height: 50px;
  overflow: hidden;
}

.experience-nav-grid {
  display: grid;
  grid-template-rows: 2fr 1fr 1fr 1fr 1fr 2fr;

  margin: auto;
  margin-right: 0;

  width: 100%;
  min-width: 400px;
  /* height: 90%; */

  top: 50%;
  transform: translateY(-50%);
  position: absolute;
}

.experience-nav-grid-item {
  /* border: 1px solid white; */

  /* font-size: 50px; */
  font-size: 3rem;

  text-align: left;

  margin-top: auto;
  margin-bottom: auto;
  margin-right: 0;

  /* display: grid;
  grid-template-columns: 0.1fr 1fr; */
}

.experience-nav-pointer {
  width: 10px;
  /* height: 5px; */
  background-color: white;

  margin-top: auto;
  margin-bottom: auto;
}

/* --- CSS Section For Timeline --- */
.timeline-grid {
  /* border: 1px solid white; */

  display: grid;
  grid-template-columns: 0.25fr 3fr 4fr;
  grid-template-rows: 1fr 0.1fr 1fr 1fr 0.5fr;

  margin-left: 1%;
  margin-top: 15px;
  width: auto;

  /* max-height: 75px; */
}

.timeline-grid-test {
  /* border: 1px solid white; */

  display: grid;
  grid-template-columns: 0.25fr 3fr 1fr;
  grid-template-rows: 1fr 0.1fr 1fr 1fr 0.5fr 5fr;

  margin-left: 1%;
  margin-top: 15px;
  /* width: auto; */

  max-height: 75px;

}

.timeline-grid-l2 {
  /* border: 1px solid white; */

  display: grid;
  grid-template-columns: 0.25fr 3fr 1fr;
  grid-template-rows: 1fr 0.1fr 1fr 1fr 0.5fr 5fr;

  margin-left: 7%;
  margin-top: 15px;

  width: auto;
  max-height: 75px;

}

.timeline-grid-item {
  /* border: 1px solid white; */
}

.italics {
  font-style: italic;
}

.font14 {
  /* font-size: 14px; */
  font-size: 0.875rem;

}

.font12 {
  /* font-size: 12px; */
  font-size: 0.75rem;
}

.semi-bold {
  font-weight: 600;
}

.vertical-line {
  border-left: 1px solid white;
  height: 120%;
  width: 1px;
  margin: auto;
}

.gridFromTo1 {
  grid-column-start: 1;
  grid-column-end: 3;
  grid-row-start: 1;
  grid-row-end: 1;
}

.gridFromTo2 {
  grid-column-start: 2;
  grid-column-end: 2;
  grid-row-start: 3;
  grid-row-end: 3;

  /* min-width: 350px; */
}

.gridFromTo2-1 {
  grid-column-start: 2;
  grid-column-end: 2;
  grid-row-start: 3;
  grid-row-end: 3;

  min-width: 350px;
  margin-right: 10px;
}

.gridFromTo3 {
  grid-column-start: 1;
  grid-column-end: 1;
  grid-row-start: 3;
  grid-row-end: 5;
}

.gridFromTo4 {
  grid-column-start: 2;
  grid-column-end: 2;
  grid-row-start: 4;
  grid-row-end: 4;
}

.gridFromTo4-test {
  grid-column-start: 2;
  grid-column-end: 4;
  grid-row-start: 5;
  grid-row-end: 5;

  margin-top: 5px;
  text-align: justify;
  color: #7D7D7D;
}

.gridFromTo5 {
  grid-column-start: 3;
  grid-column-end: 3;
  grid-row-start: 1;
  grid-row-end: 5;

  /* border: 1px solid white; */
  min-width: 440px;
}

.gridFromTo5-2 {
  grid-column-start: 3;
  grid-column-end: 3;
  grid-row-start: 1;
  grid-row-end: 5;

  /* border: 1px solid white; */
  /* min-width: 100px; */
}

.gridFromTo5-3 {
  grid-column-start: 3;
  grid-column-end: 3;
  grid-row-start: 1;
  grid-row-end: 5;

  /* border: 1px solid white; */
  /* min-width: 430px; */
}

.minWidth {
  min-width: 900px;
}

.marginLess{
  margin-left: 20px;
  padding-left: 0;
}

.colorGrey {
  color: #7D7D7D;
}



/* --- CSS Section For Timeline --- */
.conclusion-grid {
  display: grid;
  grid-template-columns: 0.1fr 3fr;
  margin-left: -30px;
  margin-top: 10px;
}

.conclusion-grid-item-text {
  margin-left: 20px;

}

.conclusion-grid-item-img {  
  margin: auto;
  
}

.widthSmall {
  width: 400px;
}

.widthSmallS {
  width: 350px;
}

.widthSmallSS {
  width: 250px;
}

.justify {
  text-align: justify;
}

/* --- CSS Section For Mission --- */

.mission-grid {
  /* border: 1px solid white; */
  display: grid;
  grid-template-columns: 3fr 1.3fr;

  margin: auto;

  width: 94vw;
  /* height: 88vh; */
}

.mission-grid-left {
  display: grid;
  grid-template-rows: 0.5fr 2fr 0.5fr;
  align-items: center;
}

.mission-grid-right {
  display: grid;
  grid-template-rows: 0.15fr 0.4fr 2fr 0.1fr;
}

.mission-grid-right2 {
  display: grid;
  grid-template-rows: 0.3fr 2fr 0.3fr;
}

.mission-grid-right2-text {
  margin-left: 10%;
  margin-right: 10%;
  text-align: justify;
}

.sub-headline {
  /* font-weight: bold; */
  display: inline-block; 
  margin: 5px;
  margin-left: 0px;

  /* color: #1A1A1A;
  background-color: #D9D9D9; */
}

.text {
  margin: 0;
  /* margin-left: 20px; */
  color: #7D7D7D
}

.mission-grid-right-quote {
  text-align: right;
  width: auto;
  height: auto;
  display: flex;
  align-items: center;
  
  color: #1A1A1A;
  background-color: #D9D9D9;

  padding: 5px;

  /* font-size: 20px; */
  font-size: 1.25rem;
  font-style: italic;
}

.mission-grid-right-explanation {
  width: 543px;
  text-align: justify;

}

.mission-grid-right-quote-container {
  display: flex;
  justify-content: center;
}

.mission-grid-left-text {
  text-align: left;
  /* font-size: 50px; */
  font-size: 3rem;
  padding-left: 10px;
  max-height: auto;
}

.border-left {
  border-left: 2px solid white ;
}

.border {
  border: 1px solid white ;
}

.MobileWarning {
  width: 99vw;
  /* height: 99vh; */
}

.MobileWarning-Text {
  font-size: 3.2vw;
  margin-top: 10%;
  margin-left: 10%;
  margin-right: 10%;
}